import { NotificationType, useGlobalNotification } from "~/store/global-notification.ts";
import { LocalFetchError } from "~/utils/errorObjects.ts";
import { ref, computed, type Ref, type ComputedRef, type UnwrapRef, type UnwrapNestedRefs } from "vue";
import type { ApiResponse, RequestOpts, WithHeaders } from "oazapfts/lib/runtime";
import { createCustomFetch, type CreateCustomFetchOpts } from "~/composables/customFetch.ts";
import { defu } from "defu";
import { TimeInMS } from "~/utils/dateTimeHelpers.ts";
import type { Store } from "pinia";
import { argumentParser } from "~/utils/argumentParser.ts";
import type { PossibleErrorResponses } from "~/utils/types";
import { createWaitForPromise } from "~/utils/helpers.ts";

export type RESOLVED_STATUS =
  | 100 //	CONTINUE	Continue
  | 101 //	SWITCHING_PROTOCOLS	Switching Protocols
  | 102 //	PROCESSING	Processing
  | 103 //	EARLY_HINTS	Early Hints
  | 200 //	OK	OK
  | 201 //	CREATED	Created
  | 202 //	ACCEPTED	Accepted
  | 203 //	NON_AUTHORITATIVE_INFORMATION	Non Authoritative Information
  | 204 //	NO_CONTENT	No Content
  | 205 //	RESET_CONTENT	Reset Content
  | 206 //	PARTIAL_CONTENT	Partial Content
  | 207 //	MULTI_STATUS	Multi-Status
  | 300 //	MULTIPLE_CHOICES	Multiple Choices
  | 301 //	MOVED_PERMANENTLY	Moved Permanently
  | 302 //	MOVED_TEMPORARILY	Moved Temporarily
  | 303 //	SEE_OTHER	See Other
  | 304 //	NOT_MODIFIED	Not Modified
  | 305 //	USE_PROXY	Use Proxy
  | 307 //	TEMPORARY_REDIRECT	Temporary Redirect
  | 308; //	PERMANENT_REDIRECT	Permanent Redirect

export type REJECTED_STATUS =
  | 0 // Cors Error
  | 400 //	BAD_REQUEST	Bad Request
  | 401 //	UNAUTHORIZED	Unauthorized
  | 402 //	PAYMENT_REQUIRED	Payment Required
  | 403 //	FORBIDDEN	Forbidden
  | 404 //	NOT_FOUND	Not Found
  | 405 //	METHOD_NOT_ALLOWED	Method Not Allowed
  | 406 //	NOT_ACCEPTABLE	Not Acceptable
  | 407 //	PROXY_AUTHENTICATION_REQUIRED	Proxy Authentication Required
  | 408 //	REQUEST_TIMEOUT	Request Timeout
  | 409 //	CONFLICT	Conflict
  | 410 //	GONE	Gone
  | 411 //	LENGTH_REQUIRED	Length Required
  | 412 //	PRECONDITION_FAILED	Precondition Failed
  | 413 //	REQUEST_TOO_LONG	Request Entity Too Large
  | 414 //	REQUEST_URI_TOO_LONG	Request-URI Too Long
  | 415 //	UNSUPPORTED_MEDIA_TYPE	Unsupported Media Type
  | 416 //	REQUESTED_RANGE_NOT_SATISFIABLE	Requested Range Not Satisfiable
  | 417 //	EXPECTATION_FAILED	Expectation Failed
  | 418 //	IM_A_TEAPOT	I'm a teapot
  | 419 //	INSUFFICIENT_SPACE_ON_RESOURCE	Insufficient Space on Resource
  | 420 //	METHOD_FAILURE	Method Failure
  | 421 //	MISDIRECTED_REQUEST	Misdirected Request
  | 422 //	UNPROCESSABLE_ENTITY	Unprocessable Entity
  | 423 //	LOCKED	Locked
  | 424 //	FAILED_DEPENDENCY	Failed Dependency
  | 426 //	UPGRADE_REQUIRED	Upgrade Required
  | 428 //	PRECONDITION_REQUIRED	Precondition Required
  | 429 //	TOO_MANY_REQUESTS	Too Many Requests
  | 431 //	REQUEST_HEADER_FIELDS_TOO_LARGE	Request Header Fields Too Large
  | 451 //	UNAVAILABLE_FOR_LEGAL_REASONS	Unavailable For Legal Reasons
  | 500 //	INTERNAL_SERVER_ERROR	Internal Server Error
  | 501 //	NOT_IMPLEMENTED	Not Implemented
  | 502 //	BAD_GATEWAY	Bad Gateway
  | 503 //	SERVICE_UNAVAILABLE	Service Unavailable
  | 504 //	GATEWAY_TIMEOUT	Gateway Timeout
  | 505 //	HTTP_VERSION_NOT_SUPPORTED	HTTP Version Not Supported
  | 507 //	INSUFFICIENT_STORAGE	Insufficient Storage
  | 511 //	NETWORK_AUTHENTICATION_REQUIRED	Network Authentication Required
  | 600; // Local Error

export enum FETCH_STATE {
  INITIAL = "INITIAL",
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
  REJECTED = "REJECTED",
}

export type LocalFetchResponse<T, T_Err extends PossibleErrorResponses> =
  | (WithHeaders<ApiResponse> & {
      status: RESOLVED_STATUS;
      data?: T;
      blob?: Blob;
    })
  | (WithHeaders<ApiResponse> & {
      status: REJECTED_STATUS;
      data?: LocalFetchError<T_Err>;
      blob?: Blob;
    });

export type MutationFetchResponse<T, T_Err extends PossibleErrorResponses> =
  | {
      resolved: true;
      rejected: false;
      status: RESOLVED_STATUS;
      errors: null;
      data: T;
    }
  | {
      resolved: false;
      rejected: true;
      status: REJECTED_STATUS;
      errors: T_Err;
      data: null;
    };

/**
 * @throws {TypeError}
 * @throws {DOMException}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GeneratedFetch<T, T_Err extends PossibleErrorResponses, Args extends any[] = any[]> = (
  ...args: Args
) => Promise<LocalFetchResponse<T, T_Err>>;

export type LocalReactiveFetchHandler<
  T_FetchType,
  T_ErrorType extends PossibleErrorResponses,
  T_FetchMethod extends GeneratedFetch<T_FetchType, T_ErrorType> = GeneratedFetch<T_FetchType, T_ErrorType>,
  T_ResponseType extends LocalFetchResponse<T_FetchType, T_ErrorType> = LocalFetchResponse<T_FetchType, T_ErrorType>,
> = {
  name: string;
  fetchState: Ref<FETCH_STATE>;
  response: Ref<UnwrapRef<T_ResponseType> | T_ResponseType>;
  promise: Ref<Promise<T_ResponseType>>;
  data: ComputedRef<T_FetchType | UnwrapRef<T_FetchType | null | undefined>>;
  blob: ComputedRef<Blob>;
  errors: ComputedRef<LocalFetchError<T_ErrorType> | null | undefined>;
  status: ComputedRef<number>;
  pending: ComputedRef<boolean>;
  initial: ComputedRef<boolean>;
  resolved: ComputedRef<boolean>;
  rejected: ComputedRef<boolean>;
  reset: () => void;
  fetch: (...args: Parameters<T_FetchMethod>) => Promise<T_ResponseType>;
  mutate: (...args: Parameters<T_FetchMethod>) => Promise<MutationFetchResponse<T_FetchType, T_ErrorType>>;
  download: (
    filename: string,
    blobOptions: BlobPropertyBag,
    ...fetchArgs: Parameters<T_FetchMethod>
  ) => Promise<T_ResponseType>;
};

class ApiRequestOpts implements RequestOpts {
  /** Override the base url for the API. */
  public baseUrl?: string;
  /** Custom Fetch handler **/
  public fetch?: typeof fetch;
  /** Custom way of creating a FormData object */
  public formDataConstructor?: new () => FormData;
  /** A Headers object, an object literal, or an array of two-item arrays to set request's headers. */
  public headers?: Record<string, string | number | boolean | undefined>;
  /** A string indicating how the request will interact with the browser's cache to set request's cache. */
  public cache?: RequestCache;
  /** A string indicating whether credentials will be sent with the request always, never, or only when sent to a same-origin URL. Sets request's credentials. */
  public credentials?: RequestCredentials;
  /** A cryptographic hash of the resource to be fetched by request. Sets request's integrity. */
  public integrity?: string;
  /** A boolean to set request's keepalive. */
  public keepalive?: boolean;
  /** A string to set request's method. */
  public method?: string;
  /** A string to indicate whether the request will use CORS, or will be restricted to same-origin URLs. Sets request's mode. */
  public mode?: RequestMode;
  /** A string indicating whether request follows redirects, results in an error upon encountering a redirect, or returns the redirect (in an opaque fashion). Sets request's redirect. */
  public redirect?: RequestRedirect;
  /** A string whose value is a same-origin URL, "about:client", or the empty string, to set request's referrer. */
  public referrer?: string;
  /** A referrer policy to set request's referrerPolicy. */
  public referrerPolicy?: ReferrerPolicy;
  /** An AbortSignal to set request's signal. */
  public signal?: AbortSignal | null;
  /** Can only be null. Used to disassociate request from any Window. */
  public window?: null;

  constructor(opts: RequestOpts) {
    Object.assign(this, opts);
  }
}

export function apiRequestOpts(opts: RequestOpts): ApiRequestOpts {
  return new ApiRequestOpts(opts);
}

/**
 * Okay so what we are doing here is we need to extract any union type that isn't null or undefined or never.
 * There is also the possibility that this returns a function somehow? not clear on why that is. BUT we need
 * to find the types where `data` is provided on an object. if that is there, then we will have a return value.
 *
 * So we need to exclude the function, get rid of the nullable types, and then extract where `data: any` exists.
 *
 * Hence, why I am disabling linting the next couple of lines. This is needed here.
 **/
export type FetchResultsData<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T_FetchMethod extends GeneratedFetch<any, any> = GeneratedFetch<any, any>,
  T_Status extends number = number,
> = Extract<
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  Exclude<NonNullable<Awaited<ReturnType<T_FetchMethod>>>, Function>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { status: T_Status; data: any }
>["data"];

/**
 *
 * @param idName - Reserved for future use
 * @param fetchMethod - Oazapfts generated fetch handler
 * @param customFetchOpts - Custom fetch options for extra handlers
 */
export function useLocalFetch<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T_FetchMethod extends GeneratedFetch<any, any> = GeneratedFetch<any, any>,
  T_FetchType extends FetchResultsData<T_FetchMethod, RESOLVED_STATUS> = FetchResultsData<
    T_FetchMethod,
    RESOLVED_STATUS
  >,
  T_FetchTypeError extends FetchResultsData<T_FetchMethod, REJECTED_STATUS> = FetchResultsData<
    T_FetchMethod,
    REJECTED_STATUS
  >,
>(
  idName: string,
  fetchMethod: T_FetchMethod,
  customFetchOpts?: CreateCustomFetchOpts,
): LocalReactiveFetchHandler<
  T_FetchType,
  T_FetchTypeError,
  T_FetchMethod,
  LocalFetchResponse<T_FetchType, T_FetchTypeError>
> {
  if (!hasInjectionContext()) {
    devConsole.error(`useLocalFetch("${idName}, ${fetchMethod.name}") Called Improperly!`);
    devConsole.error(`please only call useLocalFetch in the \`setup\` Context of a component or store!`);
    devConsole.groupCollapsed(`stackTrace`);
    devConsole.trace();
    devConsole.groupEnd();
  }

  type T_FetchResponse = LocalFetchResponse<T_FetchType, T_FetchTypeError>;

  type T_RefResponse = Ref<UnwrapRef<T_FetchResponse> | T_FetchResponse>;
  // Fetch methods are generated by Oazapfts. We need to check if there are optional parameters with a
  // set default. For those methods, if we don't add undefined before the ApiRequestOpts object, it will
  // set the ApiRequestOpts object properties as parameters.
  const argString = argumentParser(fetchMethod);
  const createEmptyResponse = (): T_FetchResponse => ({ status: 0, headers: {}, blob: new Blob() }) as T_FetchResponse;

  const __customFetch = createCustomFetch(customFetchOpts);
  const config = useRuntimeConfig();

  const globalNotification = useGlobalNotification();

  const fetchState: Ref<FETCH_STATE> = ref(FETCH_STATE.INITIAL);
  const response: T_RefResponse = ref<T_FetchResponse>(createEmptyResponse());
  const promise: Ref<Promise<T_FetchResponse>> = ref(Promise.resolve(createEmptyResponse()));
  const errors = computed<null | LocalFetchError<T_FetchTypeError>>(() =>
    response.value.status >= 400 || response.value.status === 0
      ? (response.value.data as LocalFetchError<T_FetchTypeError>)
      : null,
  );
  const data = computed<T_FetchType | null>(() =>
    response.value.status < 400 && response.value.status > 0 ? (response.value.data as T_FetchType) : null,
  );
  const blob = computed<Blob>(() => response.value.blob ?? new Blob());
  const status = computed<number>(() => response.value.status || 0);
  const pending = computed(() => {
    return fetchState.value === FETCH_STATE.PENDING;
  });
  const resolved = computed(() => {
    return fetchState.value === FETCH_STATE.RESOLVED;
  });
  const rejected = computed(() => {
    return fetchState.value === FETCH_STATE.REJECTED;
  });
  const initial = computed(() => {
    return fetchState.value === FETCH_STATE.INITIAL;
  });
  const reset = () => {
    response.value = createEmptyResponse();
    promise.value = Promise.resolve(createEmptyResponse());
    fetchState.value = FETCH_STATE.INITIAL;
  };

  const fetch = async (...args: Parameters<typeof fetchMethod>): Promise<T_FetchResponse> => {
    fetchState.value = FETCH_STATE.PENDING;
    if (argString.length > 1 && args.length === 0) {
      args.push(undefined);
    }
    const argLength = args?.length;
    let opts = args.pop();

    if (opts instanceof ApiRequestOpts) {
      opts.fetch = __customFetch;
      opts.baseUrl = config.public.API_OAZAPFTS_URL;
    } else {
      // if there are no args, we don't need to put our popped undefined arg back in the array
      if (argLength > 0) args.push(opts);
      opts = apiRequestOpts({
        baseUrl: config.public.API_OAZAPFTS_URL,
        fetch: __customFetch,
      });
    }

    args.push(opts);
    let __response: LocalFetchResponse<T_FetchType, T_FetchTypeError> = {
      status: 0,
      headers: new Headers(),
      data: new LocalFetchError("error", "This should not be seen.", 0),
    };
    try {
      promise.value = fetchMethod(...args);
      __response = await promise.value;
      __response.blob = await __customFetch.meta.response.clone().blob();
      if (__response.status === 0) {
        __response.data = new LocalFetchError("error", "CORS Error", 0);
      }
      if (__response.status >= 400) {
        fetchState.value = FETCH_STATE.REJECTED;
        try {
          const r = __customFetch.meta.response.clone();
          __response.data = new LocalFetchError("json", await r.json(), <REJECTED_STATUS>response.value.status);
        } catch (e) {
          if (e instanceof TypeError || e instanceof SyntaxError) {
            const r = __customFetch.meta.response.clone();
            __response.data = new LocalFetchError("text", await r.text(), <REJECTED_STATUS>response.value.status);
          } else {
            throw new Error("Issue deserializing response data", { cause: e });
          }
        }
      } else {
        fetchState.value = FETCH_STATE.RESOLVED;
      }
    } catch (e) {
      if (import.meta.env.DEV) {
        devConsole.log("Rejected!");
        devConsole.dir(e);
      }
      fetchState.value = FETCH_STATE.REJECTED;
      __response = {
        status: 600,
        headers: new Headers(),
        data: new LocalFetchError("error", e as Error, 600, e as Error),
        blob: new Blob(),
      };
    }

    if (import.meta.env.VITEST) {
      if (__response.status >= 500 && __response.data instanceof LocalFetchError) {
        throw new Error(
          `Api "${__customFetch.meta.request.url}" (${fetchMethod.name}) returned ${response.value.status} code with message: "${JSON.stringify(response.value.data.body, null, 2)}"`,
        );
      }
    }
    // put a fresh copy here, headers cannot be cloned. Why? ask TC39.
    const { headers, ...theRest } = __response;
    response.value = { ...structuredClone(theRest), headers: new Headers(headers) };
    // return our original object so we can get better async responses
    if (theRest.data instanceof LocalFetchError) {
      response.value.data = theRest.data;
    }
    return __response;
  };

  Object.defineProperties(fetch, {
    name: {
      get(): string {
        return fetchMethod.name;
      },
    },
    toString: {
      get(): () => string {
        return () => fetchMethod.toString();
      },
    },
    length: {
      get(): number {
        return fetchMethod.length;
      },
    },
  });

  const { t } = useI18n();
  const download = async (
    filename: string = "download",
    blobOptions: BlobPropertyBag = { type: "application/octet-stream" },
    ...fetchArgs: Parameters<typeof fetchMethod>
  ) => {
    const messageHandle = globalNotification.showPending(`${t("desc.creating")} ${filename}`);
    await fetch(...fetchArgs);
    if (resolved.value) {
      let extractedName = response.value.headers.get("content-disposition");
      if (extractedName) {
        const results = /attachment; filename="?(?<ascii>.+)"?; filename\*=UTF-8''(?<utf8>.+)/.exec(extractedName);
        devConsole.log(results?.groups);
        try {
          if (results?.groups?.ascii) {
            extractedName = decodeURIComponent(results.groups.ascii);
          }
          if (results?.groups?.utf8) {
            extractedName = decodeURIComponent(results.groups.utf8);
          }
        } catch {
          // in the event something goes wrong with decoding the uri string,
          // this will cause the download to fall back to `filename`
          extractedName = null;
        }
      }
      extractedName = extractedName ?? filename ?? "download";
      const file = blob.value;
      messageHandle
        .setFile(file, extractedName)
        .then(DO_NOTHING)
        .catch((e: Error) => {
          console.log(e);
          messageHandle.close();
        });
      messageHandle.setTimeout(TimeInMS.FIVE_SECONDS);
      messageHandle.setType(NotificationType.Success);
      messageHandle.setMessage(`${extractedName} Ready!`);
      downloadBlob(file, extractedName, blobOptions);
    } else {
      messageHandle.setTimeout(TimeInMS.TEN_SECONDS);
      messageHandle.setType(NotificationType.Error);
      messageHandle.setMessage(globalNotification.getError(errors.value!));
    }
    return response.value;
  };

  async function mutate(
    ...fetchArgs: Parameters<typeof fetchMethod>
  ): Promise<MutationFetchResponse<T_FetchType, T_FetchTypeError>> {
    const result = await fetch(...fetchArgs);

    if (result.status < 400 && result.status >= 100) {
      return {
        resolved: true,
        rejected: false,
        errors: null,
        data: vueStructuredClone(result.data),
        status: result.status as RESOLVED_STATUS,
      };
    }

    return {
      resolved: false,
      rejected: true,
      errors: result.data,
      data: null,
      status: result.status as REJECTED_STATUS,
    };
  }

  return {
    name: idName,
    fetchState,
    response,
    promise,
    data,
    blob,
    errors,
    status,
    pending,
    initial,
    resolved,
    rejected,
    reset,
    fetch,
    download,
    mutate,
  };
}

export type FetchHandler<T, T_Err extends PossibleErrorResponses = PossibleErrorResponses> = LocalReactiveFetchHandler<
  T,
  T_Err,
  GeneratedFetch<T, T_Err>,
  LocalFetchResponse<T, T_Err>
>;
export type ReactiveFetchHandler<T, T_Err extends PossibleErrorResponses = PossibleErrorResponses> = UnwrapNestedRefs<
  FetchHandler<T, T_Err>
>;

export type PiniaFetchHandler<
  T,
  T_Err extends PossibleErrorResponses = PossibleErrorResponses,
  T_Handler extends FetchHandler<T, T_Err> = FetchHandler<T, T_Err>,
> = Store<
  string,
  Pick<T_Handler, "promise" | "fetchState" | "response" | "name">,
  Pick<T_Handler, "initial" | "resolved" | "rejected" | "pending" | "errors" | "status" | "blob" | "data">,
  Pick<T_Handler, "fetch" | "download" | "reset">
>;

/**
 * To be used when we care about the shape of the fetch handler, but do not care about the actual result.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PassedFetchHandler<T = any, T_Err extends PossibleErrorResponses = PossibleErrorResponses> =
  | FetchHandler<T, T_Err>
  | ReactiveFetchHandler<T, T_Err>
  | PiniaFetchHandler<T, T_Err>;

// This type is for testing purposes.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ResolvedRequest<T = any> = { status: RESOLVED_STATUS; headers: Headers; data: T };

export type CachedFetchOptions = {
  maxCalledTimes?: number;
  maxCachedTime?: number;
};

export function useCachedFetch<T, T_Err extends PossibleErrorResponses>(
  originalFetch: GeneratedFetch<T, T_Err>,
  promise: Ref<Promise<LocalFetchResponse<T, T_Err>>>,
  options: CachedFetchOptions = {},
) {
  if (argumentParser(originalFetch).length > 1) {
    devConsole.error(
      `You cannot use ${originalFetch.name} with useCachedFetch. It has parameters that would not get invalidated on change.`,
    );
  }
  const opts = defu<Required<CachedFetchOptions>, CachedFetchOptions[]>(options, {
    maxCalledTimes: 30,
    maxCachedTime: TimeInMS.FIVE_MINUTES,
  });

  const cacheTime = ref(0);
  const calledTimes = ref(0);

  const voidCache = () => {
    cacheTime.value = 0;
    calledTimes.value = 0;
  };

  const cachedFetch = async (...args: Parameters<typeof originalFetch>): ReturnType<typeof originalFetch> => {
    // if we have fetched between 0 and 30 times since the last call, and it has been less than 5 minutes
    // just return the previous value. This call is going to be lightly cached in memory, so that multiple calls
    // will not immediately re-fetch this list.
    if (
      Date.now() - cacheTime.value < opts.maxCachedTime &&
      calledTimes.value <= opts.maxCalledTimes &&
      calledTimes.value > 0
    ) {
      calledTimes.value++;
      return promise.value;
    }

    calledTimes.value = 1;
    cacheTime.value = Date.now();

    return originalFetch(...args);
  };

  return {
    voidCache,
    cachedFetch,
  };
}

export function useIsReady<T, T_Err extends PossibleErrorResponses = PossibleErrorResponses>(
  handler: PassedFetchHandler<T, T_Err>,
) {
  const { $auth } = useNuxtApp();

  return async function isLocalFetchReady() {
    const authed = await $auth.isAuthed();
    if (authed) {
      /**
       * JS Event loop is weird, and async will get things out of sync. So we're gonna need this.
       * @private
       */
      let __promise: Promise<LocalFetchResponse<T, T_Err>>;
      if (!unref(handler.initial)) {
        // do the await this way so if we have already started, then it will listen to the promise;
        __promise = unref(handler.promise);
      } else {
        // Store this as a promise here since the promise might not get set before being called.
        __promise = handler.fetch();
      }
      await __promise;
      // we're going to wait for pending to not be the current state anymore.
      // There is something about the vue/pinia microtask queue that will prevent this from updating quickly enough in async,
      // so we have to wait for it to change ourselves. This will max out at 50ms (5ms timeout, 10 tries).
      await createWaitForPromise(() => !unref(handler.pending));
      if (unref(handler.resolved)) {
        // now we are good to go.
        return true;
      }
    }
    return false;
  };
}
