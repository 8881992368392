import { defineNuxtPlugin } from "#app";

// This file is doing weird things, do not change extension
export default defineNuxtPlugin(async () => {
  try {
    // Add spill event internally for tracking
    const { events } = await import("vuedraggable/src/core/sortableEvents.js");
    events.emit.push("Spill");
    // This is the sortable used by vuedraggable
    const Sortable = (await import("sortablejs")).default;
    // The OnSpill module to help us handle bad inputs
    const { OnSpill } = await import("sortablejs/modular/sortable.core.esm.js");
    const Draggable = (await import("vuedraggable/src/vuedraggable.js")).default;
    // add spill event for vue event propagation
    Draggable.emits.push("spill");
    // add OnSpill Plugin to handle dropping things on non targets correctly.
    Sortable.mount(OnSpill);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // swallow the already mounted error
  }
});
